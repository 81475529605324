// 省市区转树形结构
import { areaList } from '@vant/area-data'
console.log(areaList,'vant地址');


export const location = () => {
    let areaData = {
        province: [],
        city: [],
        area: []
    }
    for (let key in areaList.province_list) {
        areaData.province.push({
            name: areaList.province_list[key],
            code: key
        })
    }
    for (let key in areaList.city_list) {
        areaData.city.push({
            name: areaList.city_list[key],
            code: key
        })
    }
    for (let key in areaList.county_list) {
        areaData.area.push({
            name: areaList.county_list[key],
            code: key
        })
    }
    return areaData
}
export const locations = () => {
    let datas = []
    let data = [
        {
            areaName: '华北',
            childrenList: [],
            coding: 1000000,
            id: 1,
            level: 0,
            parentId: 0
        },
        {
            areaName: '东北',
            childrenList: [],
            coding: 2000000,
            id: 2,
            level: 0,
            parentId: 0
        },
        {
            areaName: '华东',
            childrenList: [],
            coding: 3000000,
            id: 3,
            level: 0,
            parentId: 0
        },
        {
            areaName: '华南',
            childrenList: [],
            coding: 4000000,
            id: 4,
            level: 0,
            parentId: 0
        },
        {
            areaName: '华中',
            childrenList: [],
            coding: 8000000,
            id: 8,
            level: 0,
            parentId: 0
        },
        {
            areaName: '西南',
            childrenList: [],
            coding: 5000000,
            id: 5,
            level: 0,
            parentId: 0
        },
        {
            areaName: '西北',
            childrenList: [],
            coding: 6000000,
            id: 6,
            level: 0,
            parentId: 0
        },
        {
            areaName: '港澳台',
            childrenList: [],
            coding: 7000000,
            id: 7,
            level: 0,
            parentId: 0
        }
    ]



    for (let key in areaList.province_list) {
        datas.push({
            areaName: areaList.province_list[key],
            childrenList: [],
            coding: Number(key),
            id: Number(key.slice(0, 2)),
            level: 1,
            parentId: (key == '410000' || key == '420000' || key == '430000') ? 8000000 : (key == '810000' || key == '820000') ? 7000000 : Number(key.slice(0, 1) + '000000')
        })

    }
    datas.map((item) => {
        for (let key in areaList.city_list) {
            if (key.slice(0, 2) == item.id) {
                item.childrenList.push({
                    areaName: areaList.city_list[key],
                    childrenList: [],
                    coding: Number(key),
                    id: Number(key.slice(0, 4)),
                    level: 2,
                    parentId: item.coding
                })
            }


        }
    })
    datas.map((item) => {
        item.childrenList && item.childrenList.length > 0 && item.childrenList.map((child) => {
            for (let key in areaList.county_list) {
                if (child.id == key.slice(0, 4)) {
                    child.childrenList.push({
                        areaName: areaList.county_list[key],
                        childrenList: [],
                        coding: Number(key),
                        id: Number(key),
                        level: 3,
                        parentId: child.coding
                    })
                }
            }
        })
    })

    data.map((item) => {
        datas.map((child) => {
            if (item.coding == child.parentId) {
                item.childrenList.push(child)
            }
        })
    })
    let result = [data, datas]

    return result
}
export const areaData = locations()[0]
export const areaRegion = locations()[1]
export const areaListData = location()