<template>
  <div class="gg-container">
    <div style="margin-bottom:20px;">
      <span style="font-size:25px;border-bottom:3px solid #1684FC;"
        >{{ type == "edit" ? "编辑" : "新增" }}配送模板</span
      >
    </div>
    <div class="content">
      <div style="margin-right:20px">
        <span style="color:red">*</span>模板名称
      </div>
      <el-input
        v-model="info.name"
        size="mini"
        class="input"
        placeholder="请输入模板名称"
      ></el-input>
    </div>
    <div class="content" style="align-items: flex-start;">
      <div style="margin-right:20px">
        <span style="color:red">*</span>配送区域
      </div>
      <el-cascader
        :options="options"
        filterable
        :props="props"
        clearable
        show-all-levels
        collapse-tags
        size="mini"
        style="width:300px"
        @change="selectChange"
        :value="index_list"
      ></el-cascader>
    </div>
    <div class="content">
      <div style="margin-right:20px"><span style="color:red">*</span>运费</div>
      <el-input
        v-model="info.express_fee"
        size="mini"
        class="input"
        placeholder="请输入运费"
      ></el-input>
    </div>
    <div style="height:10vh"></div>
    <div>
      <el-button
        type="primary"
        size="mini"
        @click="handleCancel"
        style="margin-right:20px"
      >
        取消
      </el-button>
      <el-button type="primary" size="mini" @click="_ok">
        确定
      </el-button>
    </div>
  </div>
</template>

<script>
import { areaData } from "@/utils/location";
import address from "@/utils/address.js";
import { postExpressAdd, putExpressEdit } from "@/newApi/template";
export default {
  name: "drawerTemplate",
  props: {
    type: {
      type: String,
      default: "add",
    },
    info: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      options: address,
      props: {
        multiple: true,
        label: "name",
        value: "code",
      },
      list: [],
      index_list: [],
    };
  },
  created() {
    console.log(this.options, areaData, this.info);
    if (this.type == "edit") {
      this.info.area_list.forEach((item) => {
        this.index_list.push([
          Number(item.region_id),
          Number(item.province_id),
          Number(item.city_id),
          Number(item.area_id),
        ]);
      });
    }
  },
  methods: {
    handleCancel() {
      this.$emit("closeDrawer");
    },
    _postExpressAdd() {
      postExpressAdd(this.info).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.$message.success("添加成功");
          this.$emit("closeDrawer");
          this.$emit("getList");
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    _putExpressEdit() {
      putExpressEdit(this.info).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.$message.success("编辑成功");
          this.$emit("closeDrawer");
          this.$emit("getList");
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    _ok() {
      this.index_list.forEach((item) => {
        this.list.push({
          region_id: item[0], //区域id
          region_name: this.getSelect(item[0], this.options), //区域名称
          province_id: item[1], //省id
          province_name: this.getSelect(item[1], this.options), //省名称
          city_id: item[2], //市id
          city_name: this.getSelect(item[2], this.options), //市名称
          area_id: item[3], //区id
          area_name: this.getSelect(item[3], this.options), //区名称
        });
      });
      console.log(this.info, this.list, this.index_list);
      this.info.area_list = this.list;
      if (
        this.info.name == "" ||
        this.info.express_fee == "" ||
        this.info.area_list.length == 0
      ) {
        this.$message.error("请填写完整信息");
        return;
      }
      if (this.type == "add") {
        this._postExpressAdd();
      } else {
        this._putExpressEdit();
      }
    },
    getSelect(code, data) {
      const item = this.findItemByCode(code, data);
      return item ? item.name : "";
    },
    findItemByCode(code, data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].code === code) {
          return data[i];
        }
        if (data[i].children) {
          const item = this.findItemByCode(code, data[i].children);
          if (item) {
            return item;
          }
        }
      }
      return null;
    },
    selectChange(e) {
      console.log(e);
      this.index_list = e;
      this.list = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.gg-container {
  width: 100%;
  height: 100vh;
  overflow: auto;
}
.content {
  display: flex;
  margin-bottom: 30px;
  align-items: center;
  overflow: auto;
}
.input {
  width: 200px;
}
</style>
